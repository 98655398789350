import Connector from './connector';

namespace MiniSocial {

    let socialLayerIframe: HTMLIFrameElement;
    let playerPoints: any;
    let optedOut : boolean = false;

    const fetchPoints = (callback) => {
        fetch(Connector.getSocialLayerData().socialServiceUrl + `/api/player/points`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Connector.getSocialLayerData().socialLayerJwt
            },
        }).then(response => response.json())
            .then(data => {
                if (data.optState === "FRESH") {
                    document.getElementById('mini-social-progression').classList.add('mini-social-updating');
                    playerPoints = {
                        points: 0,
                        level: {
                            levelStartsAt: 0,
                            pointsInLevel: 0,
                            levelEndsAt: 1,
                            prizeList: [ "BLUE_CRYSTAL" ]
                        },
                        prizesToRedeem: []
                    }
                } else if (data.optState === "OPTED_OUT") {
                    playerPoints = null;
                    const miniSocialDiv = document.getElementById('mini-social');
                    miniSocialDiv.style.display = 'none';
                    optedOut = true;
                } else {
                    playerPoints = data;
                    if (callback) {
                        callback(playerPoints);
                    }
                    document.getElementById('mini-social-progression').classList.remove('mini-social-updating');
                }
                updateVisuals();
            });
    }

    function calculateOffset(pointsCovered:number) {
        if (pointsCovered < 100) {
            return Math.max(4, 100 - pointsCovered).toString()
        } else {
            return "0"
        }
    }

    function updateVisuals() {
        if (playerPoints && !optedOut) {
            let userPoints = playerPoints.points
            let levelStartsAt = playerPoints.level.levelStartsAt
            let pointsInLevel = playerPoints.level.pointsInLevel
            let levelEndsAt = playerPoints.level.levelEndsAt
            let prizesToRedeem = playerPoints.prizesToRedeem

            let prizeToShow;
            if (prizesToRedeem.length > 0) {
                prizeToShow = prizesToRedeem[0]
            } else if (playerPoints.level.prizeList.length > 0) {
                prizeToShow = playerPoints.level.prizeList[0]
            }

            let eggImageDiv = document.getElementById("social-mini-egg-img")

            //Transform this if to a switch
            switch (prizeToShow) {
                case "BLUE_CRYSTAL":
                    eggImageDiv.classList.remove("blue-egg")
                    eggImageDiv.classList.remove("red-egg")
                    eggImageDiv.classList.remove("yellow-egg")
                    eggImageDiv.classList.add("blue-egg")
                    break;
                case "RED_CRYSTAL":
                    eggImageDiv.classList.remove("blue-egg")
                    eggImageDiv.classList.remove("red-egg")
                    eggImageDiv.classList.remove("yellow-egg")
                    eggImageDiv.classList.add("red-egg")
                    break;
                case "YELLOW_CRYSTAL":
                    eggImageDiv.classList.remove("blue-egg")
                    eggImageDiv.classList.remove("red-egg")
                    eggImageDiv.classList.remove("yellow-egg")
                    eggImageDiv.classList.add("yellow-egg")
                    break;
            }
            let thereArePrizesToRedeem = playerPoints && playerPoints.prizesToRedeem.length > 0;
            let progressionPercentage: number;
            if (thereArePrizesToRedeem) {
                progressionPercentage = 100
            } else {
                progressionPercentage = ((userPoints - levelStartsAt) / (levelEndsAt - levelStartsAt)) * 100;
            }
            console.log("Progression percentage: " + progressionPercentage);
            document.getElementById("offset-circle").setAttribute('stroke-dashoffset', calculateOffset(progressionPercentage));
        }
    }

    export const init = () => {
        window.addEventListener('message', (event) => {
            console.log({event})
            if (event.data.type === 'gameRoundStarted') {
                if (document.getElementById('mini-social-progression')) {
                    document.getElementById('mini-social-progression').classList.add('mini-social-updating');
                }
            }
            if (event.data.type === 'gameRoundEnded') {
                if (document.getElementById('mini-social-progression')) {
                    document.getElementById('mini-social-progression').classList.remove('mini-social-updating');
                    if (!optedOut) {
                        fetchPoints((playerPoints)=>{
                            socialLayerIframe.contentWindow.postMessage({type: 'fetchPoints', playerPoints}, '*');
                        });
                    }
                }
            }
            if (event.data.type === 'prizeRedeemed') {
                if (document.getElementById('mini-social-progression')) {
                    document.getElementById('mini-social-progression').classList.remove('mini-social-updating');
                    if (!optedOut) {
                        fetchPoints((playerPoints)=>{
                            socialLayerIframe.contentWindow.postMessage({type: 'fetchPoints', playerPoints}, '*');
                        });
                    }
                }
            }
            if (event.data.type === 'goToFreeSpins') {
                if (document.getElementById('mini-social-progression')) {
                    window.location.assign(window.location.toString().split("=")[0] + "=" + event.data.gameId);
                }
            }
            if (event.data.type === 'closeSocial') {
                closeSocialIframe()
            }
            if (event.data.type === 'optedOut') {
                closeSocialIframe()
                playerPoints = null
                const miniSocialDiv = document.getElementById('mini-social');
                miniSocialDiv.style.display = 'none';
            }
            if (event.data.type === 'optedIn') {
                fetchPoints((data)=>{
                    const miniSocialDiv = document.getElementById('mini-social');
                    miniSocialDiv.style.display = 'block';
                });
            }
        });
        socialLayerIframe = document.getElementById('social-iframe') as HTMLIFrameElement;
        socialLayerIframe.src = `${Connector.getSocialLayerData().socialFrontEndUrl}/?token=${Connector.getSocialLayerData().socialLayerJwt}`;
        fetchPoints(null);
        console.log("Updating socialLayerIframe.src to " + socialLayerIframe.src);
    };

    export const openSocialIframe = () => {
        document.getElementById('social-iframe-container').style.display = 'flex';
        document.body.classList.add('no-scroll');
    };

    export const closeSocialIframe = () => {
        document.getElementById('social-iframe-container').style.display = 'none';
        document.body.classList.remove('no-scroll');
    };

}

export default MiniSocial;