import SockJS from 'sockjs-client';
import { EventManager, IActionEvent } from '@lindar-joy/goosicorn-event-emitter';
import Util from './util';
import { showErrorDialog } from './errorHandling';
import Wallet from './wallet';
import GDK from './gdk';
export interface IConnectionConfig {
    playerSessionId: string;
    server: string;
    gameId: string;
    accountType: string;
}
module Connector {
    let webSocket: WebSocket;
    let gameSessionId: string;
    let userRef: string;
    let operatorId: string;
    let pendingActionTimeout: NodeJS.Timeout;
    let pendingAction: boolean;
    let gameId: string;

    let socialLayerData: any;

    export let connected: Promise<void>;

    export const defaultAction = (event: IActionEvent) => {
        //The default action is the Spin action
        // @ts-ignore
        return { type: 'spin', bet: event.bet, bfr: event.bfr };
    };

    let actionToPerform = defaultAction;

    export const connect = async (connectionConfig: IConnectionConfig) => {
        gameId = connectionConfig.gameId;
        return new Promise<void>((resolve) => {
            webSocket = new SockJS(connectionConfig.server);

            webSocket.onopen = async () => {
                if (!webSocket) {
                    console.error('WebSocket not present');
                    return;
                }
                if (webSocket.readyState !== WebSocket.OPEN) {
                    console.error(
                        'The WebSocket is not open, current ready state: ' + webSocket.readyState,
                    );
                    return;
                }
                webSocket.send(
                    JSON.stringify({
                        type: 'activation',
                        gameId: connectionConfig.gameId,
                        playerSessionId: connectionConfig.playerSessionId,
                        accountType: connectionConfig.accountType,
                    }),
                );
            };

            webSocket.onmessage = async (message) => {
                const serverEvent = JSON.parse(message.data);

                switch (serverEvent.type) {
                    case 'common:connection': {
                        gameSessionId = serverEvent.gameSessionId;
                        userRef = serverEvent.userRef;
                        socialLayerData = serverEvent.socialLayerData;
                        Util.init(serverEvent);
                        EventManager.emit('gdk:connected', serverEvent);
                        resolve();
                        break;
                    }
                    case 'engine:activation': {
                        EventManager.emit('engine:activation', serverEvent);
                        serverEvent.events.forEach((e: string) => {
                            const event = JSON.parse(e);
                            //Now we publish a gdk specific copy
                            if (event.type.endsWith(':config')) {
                                EventManager.emit('gdk:config', JSON.parse(e));
                            }
                            if (event.type.endsWith(':show')) {
                                EventManager.emit('gdk:show', JSON.parse(e));
                            }

                            //We publish the original event
                            EventManager.emit(event.type, event);
                        });
                        break;
                    }
                    case 'engine:action': {
                        if (pendingAction) {
                            clearTimeout(pendingActionTimeout);
                            pendingAction = false;
                        }
                        EventManager.emit('engine:action', serverEvent);
                        serverEvent.events.forEach((e: string) => {
                            const event = JSON.parse(e);
                            EventManager.emit(event.type, event);
                        });
                        break;
                    }
                    case 'common:error': {
                        if (pendingAction) {
                            clearTimeout(pendingActionTimeout);
                            pendingAction = false;
                        }
                        EventManager.emit(serverEvent.type, serverEvent);
                        showErrorDialog(serverEvent);
                        console.error(serverEvent);
                        break;
                    }
                    case 'common:balance': {
                        Wallet.parse(serverEvent);
                        EventManager.emit('gdk:balance', serverEvent);
                        break;
                    }
                    default: {
                        if (serverEvent.type) {
                            EventManager.emit(serverEvent.type, serverEvent);
                            break;
                        } else {
                            console.debug({ serverEvent });
                            break;
                        }
                    }
                }
            };

            webSocket.onclose = async (closeData) => {
                console.debug('Closing WebSocket...');
                console.debug({ closeData });
                EventManager.emit('gdk:close', { closeData });
            };
        });
    };

    export const updateActionToPerform = (fun) => {
        if (fun) {
            actionToPerform = fun;
        } else {
            actionToPerform = defaultAction;
        }
    };

    export const fireAction = async (action: IActionEvent) => {
        if (!webSocket) {
            console.error('WebSocket not present');
            showErrorDialog({ errorCode: 'WEBSOCKET_CLOSED' });
            return;
        }
        if (!gameSessionId) {
            console.error('GameSessionId not present');
            showErrorDialog({ errorCode: 'MISSING_GAME_SESSION' });
            return;
        }
        if (webSocket.readyState !== WebSocket.OPEN) {
            console.error(
                'The WebSocket is not open, current ready state: ' + webSocket.readyState,
            );
            await connect(GDK.getConnectionData());
        }
        pendingActionTimeout = setTimeout(() => {
            showErrorDialog({ errorCode: 'WEBSOCKET_CLOSED' });
        }, 7000);
        pendingAction = true;
        webSocket.send(
            JSON.stringify({ type: 'action', gameSessionId, gameAction: JSON.stringify(action) }),
        );
    };

    EventManager.on('magic-ui:action', (event) => {
        fireAction(actionToPerform(event));
    });

    export const getGameId = () => gameId;

    export const getUserRef = () => {
        return userRef;
    };
    export const getOperatorId = () => {
        return operatorId;
    }
    export const getGameSessionID = () => {
        return gameSessionId;
    };

    export const getSocialLayerData = () => {
        return socialLayerData;
    }
}

export default Connector;
