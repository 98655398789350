import Connector from './connector';
import Util from './util';

module GameHistory {
    let actionHandler: (actionHandler) => HTMLElement;

    interface IGameHistoryRound {
        bet: number;
        win: number;
        id: string;
        date: number;
    }

    interface IGameHistoryRoundAction {
        newGameState: string;
        gameActionJson: string;
        bet: Array<IGameHistoryTransaction>;
        win: Array<IGameHistoryTransaction>;
        actionId: string;
        gameEvents: Array<string>;
    }

    interface IGameHistoryRoundDetails {
        gameType: string;
        gameRoundId: string;
        gameId: string;
        bets: Array<IGameHistoryTransaction>;
        wins: Array<IGameHistoryTransaction>;
        created: string;
        actions: Array<IGameHistoryRoundAction>;
    }

    interface IGameHistoryTransaction {
        currency: string;
        amount: number;
    }

    interface IGameHistoryWinning {
        id: string;
        winningType: string;
        winnings: number;
        mainSymbol: string;
        prizeType: string;
    }

    interface IGameHistoryReel {
        symbols: Array<string>;
    }

    export const addGameActionHandler = (
        handler: (actionHandler: IGameHistoryRoundAction) => HTMLElement,
    ) => {
        actionHandler = handler;
    };

    export const fetchGameHistory = async () => {
        const url = window.location.href;
        const urlParams = new URL(url).searchParams;
        const table = document.getElementById('game-history-result-table');
        const tableData = document.getElementById('game-history-table-data');
        const history = document.getElementById('game-history-loading');

        let server = urlParams.get('server');

        if (!server) {
            console.error('Server not found');

            return;
        }

        server = server.replace('/game', '/gameRounds');

        tableData && (tableData.innerHTML = '');
        history && (history.style.visibility = 'unset');
        table && (table.style.visibility = 'hidden');

        fetch(
            `${server}?userRef=${Connector.getUserRef()}&gameId=${urlParams.get(
                'gameId',
            )}&pageSize=50&page=0`,
        )
            .then((response) => response.json())
            .then((data) => {
                data.forEach((round: IGameHistoryRound) => {
                    const row = document.createElement('tr');
                    row.style.cursor = 'pointer';
                    row.onclick = () => {
                        openGameHistoryRound(round.id);
                    };
                    const date = new Date(round.date);
                    const elString = `
                        <td>${('0' + date.getDate()).slice(-2)}/${(
                        '0' +
                        (date.getMonth() + 1)
                    ).slice(-2)}/${date.getFullYear()} ${date
                        .getHours()
                        .toString()
                        .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date
                        .getSeconds()
                        .toString()
                        .padStart(2, '0')}</td>
                        <td>${Util.toMoney(round.bet, true)}</td>
                        <td>${Util.toMoney(round.win, true)}</td>
                    `;
                    row.innerHTML = elString;
                    tableData?.appendChild(row);
                });
            })
            .then(() => {
                const history = document.getElementById('game-history-loading');
                const table = document.getElementById('game-history-result-table');

                history && (history.style.visibility = 'hidden');
                table && (table.style.visibility = 'unset');
            });
    };

    const openGameHistoryRound = async (id: string) => {
        const url = window.location.href;
        const urlParams = new URL(url).searchParams;
        const roundsTable = document.getElementById('game-history-result-table');
        const detailsTable = document.getElementById('game-history-round-details');
        const content = document.getElementById('game-history-round-content');
        const history = document.getElementById('game-history-loading');

        content && (content.innerHTML = '');
        detailsTable && (detailsTable.style.display = 'block');
        roundsTable && (roundsTable.style.display = 'none');
        history && (history.style.visibility = 'initial');

        let server = urlParams.get('server');

        if (!server) {
            console.error('Server not found');

            return;
        }

        server = server.replace('/game', '/gameRounds');

        const response = await fetch(`${server}/gameRound?gameRoundId=${id}`);
        const roundData = (await response.json()) as IGameHistoryRoundDetails;

        history && (history.style.display = 'none');

        const mainRoundDetails = document.createElement('table');

        const totalBet = roundData.bets
            .filter((betEntry) => betEntry.currency === 'fiat')
            .reduce((p, c) => p + c.amount, 0);

        const totalWin = roundData.wins
            .filter((winEntry) => winEntry.currency === 'fiat')
            .reduce((p, c) => p + c.amount, 0);

        const date = new Date(roundData.created);

        mainRoundDetails.appendChild(createRow('Game Round ID', roundData.gameRoundId));

        mainRoundDetails.appendChild(createRow('Game ID', roundData.gameId));

        mainRoundDetails.appendChild(
            createRow(
                'Date',
                `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(
                    -2,
                )}/${date.getFullYear()}`,
            ),
        );

        mainRoundDetails.appendChild(
            createRow(
                'Time',
                `${date.getHours().toString().padStart(2, '0')}:${date
                    .getMinutes()
                    .toString()
                    .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`,
            ),
        );

        mainRoundDetails.appendChild(createRow('Total Bet', Util.toMoney(totalBet, true)));

        mainRoundDetails.appendChild(createRow('Total Win', Util.toMoney(totalWin, true)));

        content?.appendChild(mainRoundDetails);

        const actionsTitle = document.createElement('h3');
        actionsTitle.innerText = 'Actions';
        content?.append(actionsTitle);
        roundData.actions.forEach((actionData) => {
            if (actionHandler) {
                content?.appendChild(actionHandler(actionData));
            } else {
                switch (roundData.gameType) {
                    case 'instant':
                        content?.appendChild(renderInstantAction(actionData));
                        break;
                    default:
                        content?.appendChild(renderAction(actionData));
                }
            }
        });
    };

    const renderAction = (actionData: IGameHistoryRoundAction) => {
        const actionTable = document.createElement('table');
        actionTable.style.marginTop = '20px';
        actionTable.style.borderTop = '1px solid gray';
        const events = actionData.gameEvents.map((event) => JSON.parse(event));
        let newGameState = JSON.parse(actionData.newGameState);
        const playerActionData = JSON.parse(actionData.gameActionJson);
        let state;
        if (!newGameState.stateStack && newGameState.stateMap) {
            newGameState = JSON.parse(newGameState.stateMap[newGameState.betLevel]);
        }
        state = newGameState.stateStack[newGameState.stateStack.length - 1];

        const totalBet = actionData.bet
            .filter((betData) => betData.currency === 'fiat')
            .reduce((p, c) => p + c.amount, 0);
        const totalWin = actionData.win
            .filter((winData) => winData.currency === 'fiat')
            .reduce((p, c) => p + c.amount, 0);
        console.info(`🪄 Game sate:` + newGameState);

        actionTable.appendChild(createRow('Action ID:', actionData.actionId));
        if (playerActionData.bfr !== undefined) {
            actionTable.appendChild(
                createRow(
                    'Action Type:',
                    `${playerActionData.type.toUpperCase()} (BONUS FREE ROUND)`,
                ),
            );
        } else {
            actionTable.appendChild(createRow('Action Type:', playerActionData.type.toUpperCase()));
        }

        actionTable.appendChild(createRow('Total Bet:', Util.toMoney(totalBet, true)));
        actionTable.appendChild(createRow('Total Win:', Util.toMoney(totalWin, true)));

        actionTable.appendChild(createRow('Previous Game State:', newGameState.stateStack[0]));
        if (state.startsWith('FS')) {
            actionTable.appendChild(createRow('Current Game State:', 'Free Spins'));
            actionTable.appendChild(
                createRow(
                    'Special Symbol:',
                    `<img style="width: 80px" src="./partials/${state.split('_')[1]}.png"/>`,
                    true,
                ),
            );
        } else {
            actionTable.appendChild(createRow('Current Game State:', state));
        }
        if (newGameState.freeSpinsStates[state]) {
            actionTable.appendChild(
                createRow(
                    'Free Spins Remaining:',
                    newGameState.freeSpinsStates[state].freeSpinRemaining,
                ),
            );
        }

        const override = getEvent('magic-engine:symbolOverride', events);

        if (state.startsWith('FS') || state === 'FREESPINS') {
            if (override) {
                const specialSymbol = state.substr(3, 2);
                const expandWin = newGameState.freeSpinsStates[state].winnings.find(
                    (winning) =>
                        winning.mainSymbol === specialSymbol &&
                        winning.winningType === 'SCATTER' &&
                        winning.winnings > 0,
                );
                if (expandWin && newGameState.freeSpinsStates[state].winnings.length > 0) {
                    actionTable.appendChild(
                        createRow(
                            'Original Panel Result:',
                            renderReels(override.originalPanel.reels),
                            true,
                        ),
                    );
                    actionTable.appendChild(
                        createRow(
                            'Panel Result:',
                            renderReels(newGameState.freeSpinsStates[state].panel.reels),
                            true,
                        ),
                    );
                    actionTable.appendChild(
                        createRow(
                            'Winnings:',
                            renderWinnings(newGameState.freeSpinsStates[state].winnings),
                            true,
                        ),
                    );
                } else {
                    actionTable.appendChild(
                        createRow(
                            'Panel Result:',
                            renderReels(newGameState.freeSpinsStates[state].panel.reels),
                            true,
                        ),
                    );
                }
            } else {
                actionTable.appendChild(
                    createRow(
                        'Panel Result:',
                        renderReels(newGameState.freeSpinsStates[state].panel.reels),
                        true,
                    ),
                );
                if (newGameState.freeSpinsStates[state].winnings.length > 0) {
                    actionTable.appendChild(
                        createRow(
                            'Winnings:',
                            renderWinnings(newGameState.freeSpinsStates[state].winnings),
                            true,
                        ),
                    );
                }
            }
        } else {
            actionTable.appendChild(
                createRow('Panel Result:', renderReels(newGameState.mainState.panel.reels), true),
            );
            if (newGameState.mainState.winnings.length > 0) {
                actionTable.appendChild(
                    createRow('Winnings:', renderWinnings(newGameState.mainState.winnings), true),
                );
            }
        }
        return actionTable;
    };

    const renderInstantAction = (actionData: IGameHistoryRoundAction) => {
        const actionTable = document.createElement('table');
        actionTable.style.marginTop = '20px';
        actionTable.style.borderTop = '1px solid gray';
        const events = actionData.gameEvents.map((event) => JSON.parse(event));
        const newGameState = JSON.parse(actionData.newGameState);
        const playerActionData = JSON.parse(actionData.gameActionJson);
        const state = newGameState.stateStack[newGameState.stateStack.length - 1];
        const totalBet = actionData.bet
            .filter((betData) => betData.currency === 'fiat')
            .reduce((p, c) => p + c.amount, 0);
        const totalWin = actionData.win
            .filter((winData) => winData.currency === 'fiat')
            .reduce((p, c) => p + c.amount, 0);
        console.info(`🪄 new game state:` + newGameState);

        actionTable.appendChild(createRow('Action ID:', actionData.actionId));
        actionTable.appendChild(createRow('Action Type:', playerActionData.type.toUpperCase()));
        actionTable.appendChild(createRow('Total Bet:', Util.toMoney(totalBet, true)));
        actionTable.appendChild(createRow('Total Win:', Util.toMoney(totalWin, true)));

        actionTable.appendChild(createRow('Previous Game State:', newGameState.stateStack[0]));
        if (state.startsWith('FS')) {
            actionTable.appendChild(createRow('Current Game State:', 'Free Spins'));
            actionTable.appendChild(
                createRow(
                    'Special Symbol:',
                    `<img style="width: 80px" src="./partials/${state.split('_')[1]}.png"/>`,
                    true,
                ),
            );
        } else {
            actionTable.appendChild(createRow('Current Game State:', state));
        }
        return actionTable;
    };

    const getEvent = (eventType, events: Array<any>) => {
        return events.find((event) => event.type === eventType);
    };

    const renderWinnings = (winnings: Array<IGameHistoryWinning>) => {
        const winningsContainer = document.createElement('div');
        winnings.forEach((winning) => {
            const winningsTable = document.createElement('table');
            winningsTable.style.marginBottom = '20px';
            const idRow = createRow('Winning Line ID', winning.id);
            const typeRow = createRow('Winning Type', winning.winningType);
            const prizeTypeRow = createRow('Prize Type', winning.prizeType);
            const winAmountRow = createRow('Win Amount', Util.toMoney(winning.winnings, true));
            const winningSymbolAmountRow = createRow(
                'Main Winning Symbol',
                `<img style="max-width: 100px; max-height: 100px" src='./partials/${winning.mainSymbol}.png' />`,
                true,
            );

            winningsTable.appendChild(typeRow);
            winningsTable.appendChild(prizeTypeRow);
            winningsTable.appendChild(idRow);

            winningsTable.appendChild(winAmountRow);
            winningsTable.appendChild(winningSymbolAmountRow);
            winningsContainer.appendChild(winningsTable);
        });

        return winningsContainer.outerHTML;
    };

    const renderReels = (reels: IGameHistoryReel[]) => {
        const panelContainer = document.createElement('div');
        panelContainer.style.display = 'flex';
        reels.forEach((reel, reelIndex) => {
            const reelContainer = document.createElement('div');
            reelContainer.style.display = 'flex';
            reelContainer.style.flexDirection = 'column';
            reelContainer.style.flexGrow = '1';

            let wiExCreated = false;

            reel.symbols.forEach((symbol, symbolIndex) => {
                if (symbol === 'WI_EX') {
                    if (wiExCreated) {
                        return;
                    }

                    wiExCreated = true;

                    const symbolContainer = document.createElement('div');
                    symbolContainer.style.display = 'flex';
                    symbolContainer.style.alignItems = 'center';
                    symbolContainer.style.height = '300px';
                    symbolContainer.style.justifyContent = 'center';
                    symbolContainer.style.backgroundSize = 'contain';
                    symbolContainer.style.backgroundPosition = 'center';
                    symbolContainer.style.backgroundRepeat = 'no-repeat';
                    symbolContainer.id = `symbol-container-${reelIndex}-${symbolIndex}`;
                    symbolContainer.style.backgroundImage = `url('./partials/${symbol}.png')`;
                    symbolContainer.style.backgroundSize = 'cover';

                    reelContainer.appendChild(symbolContainer);

                    return;
                }

                const symbolContainer = document.createElement('div');
                symbolContainer.style.display = 'flex';
                symbolContainer.style.alignItems = 'center';
                symbolContainer.style.height = '57px';
                symbolContainer.style.justifyContent = 'center';
                symbolContainer.style.backgroundSize = 'contain';
                symbolContainer.style.backgroundPosition = 'center';
                symbolContainer.style.backgroundRepeat = 'no-repeat';
                symbolContainer.id = `symbol-container-${reelIndex}-${symbolIndex}`;
                symbolContainer.style.backgroundImage = `url('./partials/${symbol}.png')`;

                reelContainer.appendChild(symbolContainer);
            });
            panelContainer.appendChild(reelContainer);
        });
        return panelContainer.outerHTML;
    };

    export const createRow = (label: string, value: string, htmlMode = false) => {
        const row = document.createElement('tr');
        const rowLabel = document.createElement('th');
        const rowValue = document.createElement('td');
        row.appendChild(rowLabel);
        row.appendChild(rowValue);
        rowLabel.innerText = label;
        if (htmlMode) {
            rowValue.innerHTML = value;
        } else {
            rowValue.innerText = value;
        }

        return row;
    };
}

export default GameHistory;
