import { EventManager } from '@lindar-joy/goosicorn-event-emitter';
import { AnimateFromTo } from './animateFromTo';
import Connector from './connector';
import GDK from './gdk';
import { MobileCheck } from './mobileCheck';

module DFGUI {
    let tierTable: any;
    let points: number;
    let messageButtonActive = false;
    let initialised = false;
    let newPrizes: any[] = [];
    let datesInitialised = false;
    let updatedDates;
    let prizes = {};
    let tierTableInitialised = false;
    let settings;

    export const action = () => {
        if (messageButtonActive) {
            console.info(`🪄 ` + 'Performing action');
            setMessageButtonActivity(false);
            parent.postMessage({ type: 'gameRoundStarted' }, '*');
            EventManager.emit('magic-ui:action', {
                bet: 0,
            });
        }
    };

    const closePrizeInfo = (origin) => {
        const prizeInfo = document.getElementById('prize-info');
        const container = document.getElementById('prize-info-container');

        prizeInfo?.classList.add('outro');
        container?.classList.add('outro');

        prizeInfo?.classList.remove('intro');
        container?.classList.remove('intro');
        const rect = origin.getBoundingClientRect();

        if (!prizeInfo) {
            console.error('Prize info not found');

            return;
        }

        AnimateFromTo(
            prizeInfo,
            container!.clientWidth / 2,
            container!.clientHeight / 2,
            200,
            rect.top + rect.height / 2,
            500,
            true,
        );
    };

    function keyFor(keyId, params?: any) {
        if (keyId.startsWith('prizeName_')) {
            return keyId.split('_')[1];
        }
        if (keyId.startsWith('prizeDesc_')) {
            return `Description for ${keyId.split('_')[1]}`;
        }
        if (keyId.startsWith('information')) {
            return `This prize unlocks when you reach <strong>${params[0]} points</strong>. Don't forget to use your free spin every day and rack up those points - more points equals juicier prizes!`;
        }
        return keyId;
    }

    function informationWindow(
        prizeConfig: IPrize,
        title: string = '',
        name: string,
        smallDesc: string,
        description: string,
        origin?: HTMLElement,
        earned: boolean = false,
    ) {
        console.info(`🪄 prizeConfig: ` + prizeConfig);
        document.getElementById('prize-info-container')?.addEventListener('pointerdown', (evt) => {
            // @ts-ignore
            if (evt.target.id === 'prize-info-container') {
                closePrizeInfo(origin);
            }
        });
        const button = document.getElementById('prize-info-close-button');
        if (button) {
            button.onclick = () => {
                closePrizeInfo(origin);
            };
        }
        const prizeInfo = document.getElementById('prize-info');

        const container = document.getElementById('prize-info-container');

        const content = document.getElementById('prize-info-content');
        if (content) {
            content.innerHTML = '';
        }
        const h3 = document.createElement('h3');
        h3.innerHTML = title;
        content?.appendChild(h3);

        const h1 = document.createElement('h1');
        content?.appendChild(h1);
        h1.innerHTML = name;
        h1.style.fontWeight = 'bold';

        const h2 = document.createElement('h2');
        content?.appendChild(h2);
        h2.style.marginTop = '10px';
        h2.style.fontSize = '18px';
        const extras = prizeConfig.extraValues[0];

        if (extras.type === 'FREE_SPINS') {
            // @ts-ignore
            h2.innerHTML = `in ${extras.body.gameName}`;
            const totalBet = document.createElement('h2');
            totalBet.style.marginTop = '10px';
            totalBet.style.fontSize = '20px';
            // @ts-ignore
            totalBet.innerText = `${extras.body.totalBet} each`;
            content?.appendChild(totalBet);

            const terms = document.createElement('p');
            terms.style.marginTop = '10px';
            terms.style.fontSize = '16px';
            terms.style.marginBottom = '0px';
            // @ts-ignore
            terms.innerText = `Free spins expire in ${extras.body.validForDays} days.`;
            content?.appendChild(terms);
        } else if (extras.type === 'CASH') {
            // @ts-ignore
            h2.innerHTML = extras.body.additionalTerms;
        }
        if (!earned) {
            const pInfo = document.createElement('p');
            content?.appendChild(pInfo);
            pInfo.innerHTML = description;
            pInfo.style.fontSize = 'small';
            pInfo.style.lineHeight = '1.5';
        } else {
            if (extras.cta) {
                const actionButton = document.createElement('button');
                if (extras.cta.type === 'CLOSE') {
                    actionButton.innerText = 'Close';
                    actionButton.onclick = () => {
                        this.close();
                    };
                } else {
                    actionButton.innerText = extras.cta.title;
                    actionButton.onclick = () => {
                        window.open(extras.cta.url, '_blank');
                    };
                }

                content?.appendChild(actionButton);
            }
        }

        if (container) {
            container.classList.add('intro');
            container.classList.remove('outro');
        }

        if (prizeInfo) {
            prizeInfo.style.display = 'block';
            prizeInfo.classList.add('intro');
            prizeInfo.classList.remove('outro');
            // @ts-ignore
            const rect = origin.getBoundingClientRect();

            AnimateFromTo(
                prizeInfo,
                200,
                rect.top + rect.height / 2,
                container!.clientWidth / 2,
                container!.clientHeight / 2,
                500,
            );
        }
    }

    interface IFreeSpinsPrizeBody {
        prizeTemplateType: string;
        templateRef: string;
        validForDays: number;
        reason: string;
        createdBy: string;
        lastModifiedBy: string;
        gameName: string;
        totalBet: string;
        expiryDate: string;
        additionalTerms: string;
    }

    interface ICashPrizeBody {
        additionalTerms: 'Cash credited to your account, no strings attached!';
        amount: 170;
        prizeTemplateType: 'PrizeCash';
        reason: 'Some reason';
        reasonCategory: 'GAME_FAULT';
        setBy: 'stefano';
    }

    interface ICallToAction {
        type: string;
        url: string;
        title: string;
    }

    interface IExtraValue {
        type: string;
        title: string;
        body: IFreeSpinsPrizeBody | ICashPrizeBody;
        cta: ICallToAction;
    }

    interface IPrize {
        prizeName: string;
        prizeBarName: string;
        tokensNeeded: number;
        extraValues: IExtraValue[];
    }

    function renderTierTableAndPoints() {
        console.info(`🪄 ` + 'Rendering tier table');
        function createPointPrize(
            prizeConfig: IPrize,
            tokensNeeded: number,
            isTheFirst: boolean,
            isNextPrize: boolean,
            isAwarded: boolean,
            position: number,
            index,
        ) {
            const pointPrize = document.createElement('div');
            pointPrize.classList.add('point-prize');
            pointPrize.id = `prize-${tokensNeeded}-points`;
            // @ts-ignore
            prizes[tokensNeeded] = pointPrize;
            pointPrize.style.top = position + '%';
            if (!tierTableInitialised) {
                if (isAwarded) {
                    pointPrize.classList.add('point-prize-awarded');
                }
            }
            const sup = document.createElement('sup');
            if (isTheFirst) {
                sup.innerHTML = 'Top prize';
            } else if (isNextPrize) {
                sup.innerHTML = 'Next prize';
            }
            const p = document.createElement('p');
            p.innerHTML = prizeConfig.prizeBarName;

            const sub = document.createElement('sub');
            sub.innerHTML = `${tokensNeeded.toString()} pts`;

            pointPrize.appendChild(sup);
            pointPrize.appendChild(p);
            pointPrize.appendChild(sub);

            pointPrize.onclick = (evt) => {
                let title;
                if (isTheFirst) {
                    title = 'Top prize';
                } else if (isNextPrize) {
                    title = 'Next prize';
                } else {
                    switch (tierTable.length - index) {
                        case 2:
                            title = 'Second prize';
                            break;
                        case 3:
                            title = 'Third prize';
                            break;
                        case 4:
                            title = 'Fourth prize';
                            break;
                        case 5:
                            title = 'Fifth prize';
                            break;
                        case 6:
                            title = 'Sixth prize';
                            break;
                        case 7:
                            title = 'Seventh prize';
                            break;
                        case 8:
                            title = 'Eighth prize';
                            break;
                        case 9:
                            title = 'Ninth prize';
                            break;
                        case 10:
                            title = 'Tenth prize';
                            break;
                        default:
                            title = '';
                    }
                }
                informationWindow(
                    prizeConfig,
                    title,
                    keyFor(`prizeName_${prizeConfig.prizeName}`),
                    keyFor(`prizeDesc_${prizeConfig.prizeName}`),
                    keyFor('information', [tokensNeeded]),
                    pointPrize,
                );
            };

            return pointPrize;
        }

        if (tierTable && Number.isInteger(points)) {
            const pointPrizesHtml = document.getElementById('point-prizes');
            if (pointPrizesHtml) {
                pointPrizesHtml.innerHTML = '';
            }

            const topPoints = Math.max.apply(
                Math,
                tierTable.map((tier: any) => tier.tokensNeeded),
            );

            tierTable
                .sort((a: any, b: any) => {
                    return b.tokensNeeded - a.tokensNeeded;
                })
                .forEach((it: any, index: number) => {
                    console.info(`🪄 ` + it.prizeName, it.tokensNeeded);
                    const prizeName = it.prizeName;
                    const tokensNeeded = it.tokensNeeded;
                    const isTheLastPrize = !tierTable[index + 1];
                    const isAwarded = tokensNeeded <= points;
                    const isNextPrize =
                        !isAwarded &&
                        (isTheLastPrize ||
                            (!isTheLastPrize && tierTable[index + 1].tokensNeeded <= points));
                    const position = 100 - (tokensNeeded * 100) / topPoints;
                    if (pointPrizesHtml) {
                        pointPrizesHtml.appendChild(
                            createPointPrize(
                                it,
                                tokensNeeded,
                                index === 0,
                                isNextPrize,
                                isAwarded,
                                position,
                                index,
                            ),
                        );
                    }
                });

            if (!initialised) {
                initialised = true;
                document.getElementById('points')!.innerHTML = points.toString();
                document.getElementById('point-progress')!.style.height =
                    (points * 100) / topPoints + '%';
            }
        }
        tierTableInitialised = true;
    }

    async function renderGameProgress(datesEvent: any) {
        const dates = datesEvent.dates;
        const today = datesEvent.today;
        for (const day in dates) {
            const spot = document.getElementById(`day-${day}-spot`);
            spot!.className = 'day-spot';
            if (dates[day] === 'PLAYED') {
                spot?.classList.add('day-spot-played');
                document.getElementById(`day-${day}`)?.classList.add('day-letter-played');
            } else if (dates[day] === 'NOT_PLAYED' || dates[day] === 'LOADED_NOT_PLAYED') {
                document.getElementById(`day-${day}`)?.classList.add('day-letter-skipped');
                spot?.classList.add('day-spot-skipped');
            }
        }
        const todaySpot = document.getElementById(`day-${today}-spot`);
        if (dates[today] === 'PLAYED') {
            todaySpot?.classList.add('day-spot-current-day-played');
        } else {
            todaySpot?.classList.add('day-spot-current-day');
        }
    }

    function registerHandlers() {
        EventManager.on('dfg:prizes-config', (event) => {
            tierTable = event.dfgConfig.tierTable.sort((it) => it.tokensNeeded);
            if (Number.isInteger(points)) {
                renderTierTableAndPoints();
            }
        });

        EventManager.on('dfg:prizes-dates', (event) => {
            if (!datesInitialised) {
                if (event.dates[event.today] === 'PLAYED' || !event.dates[event.today]) {
                    // @ts-ignore
                    EventManager.emit('dfg-ui:cannot-play', {});
                    showCloseButton();
                }
                renderGameProgress(event);
                datesInitialised = true;
            } else {
                updatedDates = event;
            }
        });

        EventManager.on('dfg:new-prizes', (event) => {
            newPrizes = event.newPrizesAwarded as any[];
        });

        EventManager.on('gdk:balance', (event) => {
            const magicWalletBalance = event.magicWalletBalance;
            points = magicWalletBalance['TOK'] || 0;
            if (tierTable) {
                renderTierTableAndPoints();
            }
        });
    }

    export const fetchPersistentItems = async () => {
        const url = window.location.href;
        const urlParams = new URL(url).searchParams;
        const response = await fetch(
            `${urlParams
                .get('server')
                ?.replace(
                    '/game',
                    '/clientPersistence',
                )}?userRef=${Connector.getUserRef()}&gameId=${urlParams.get(
                'gameId',
            )}&gameSessionId=${Connector.getGameSessionID()}`,
        );
        if (response.status === 404) {
            return {};
        } else {
            const json = await response.json();
            return json;
        }
    };

    export const setPersistentItem = async (key: string, value: any) => {
        // @ts-ignore
        settings[key] = value;
        const url = window.location.href;
        const urlParams = new URL(url).searchParams;

        await fetch(`${urlParams.get('server')?.replace('/game', '/clientPersistence')}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                // @ts-ignore
                data: JSON.stringify(settings),
                userRef: Connector.getUserRef(),
                gameId: urlParams.get('gameId'),
                gameSessionId: Connector.getGameSessionID(),
            }),
        });
    };

    export const init = async (
        gameDiv: HTMLElement,
        mainGameClient: string,
        callback: () => void,
        alternativeUIAddress: string,
    ) => {
        registerHandlers();
        console.info(`🪄 ` + 'Initialising DFG-UI...');
        let baseUrl = alternativeUIAddress;
        if (!baseUrl) {
            baseUrl = 'https://magic-ui.goosicorn.com';
        }

        fetch(`${baseUrl}/html/dfg-ui.html`)
            .then((response) => response.text())
            .then((uiHTML) => {
                const uiDiv = document.createElement('div');
                uiDiv.id = 'dfg-ui-div';
                uiDiv.innerHTML = uiHTML;
                gameDiv.appendChild(uiDiv);
                console.info(`🪄 ` + 'DFG-UI loaded');
                parent.postMessage({ type: 'gameReady' }, '*');
                fetch(mainGameClient)
                    .then((response) => response.text())
                    .then(async (gameHTML) => {
                        const gameClient = document.getElementById('game-client');
                        gameClient!.innerHTML = gameHTML;
                        if (MobileCheck()) {
                            document.getElementById('orientation-prompt')!.classList.add('active');
                        }
                        GDK.init();
                        console.info(`🪄 ` + 'GDK Initialised');
                        callback();
                        EventManager.on('gdk:connected', async () => {
                            console.info(`🪄 ` + 'GDK Connected');
                            settings = await fetchPersistentItems();
                            if (!settings['tutorial-shown']) {
                                openTutorial();
                            }
                        });
                    });
            })
            .catch((error) => {
                console.error('Unable to initialise DFG-UI');
                console.error({ error });
            });
    };

    export const setInfoBoxMessage = (innerHTMl: string) => {
        document.getElementById('info-box')!.innerHTML = innerHTMl;
    };

    export const setMessageButton = (msg: string) => {
        document.getElementById('message-button')!.innerHTML = msg;
    };

    export const setMessageButtonActivity = (active: boolean) => {
        const mainButton = document.getElementById('main-button');
        if (active && !messageButtonActive) {
            messageButtonActive = true;
            mainButton?.classList.remove('active');
            mainButton?.classList.add('active');
        } else {
            messageButtonActive = false;
            mainButton?.classList.remove('active');
        }
    };

    export const showEarnedPrizes = async () => {
        if (newPrizes.length > 0) {
            newPrizes.forEach((prize) => {
                const pointPrize = document.getElementById(`prize-${prize.tokensNeeded}-points`);

                if (!pointPrize) {
                    console.error('Point prize not found');
                    return;
                }

                informationWindow(
                    prize,
                    'Congrats!',
                    prize.prizeName,
                    prize.prizeName,
                    '',
                    pointPrize,
                    true,
                );
            });
        }
    };

    export const showCloseButton = async () => {
        document
            .getElementById('main-button')
            ?.parentElement?.removeChild(document.getElementById('main-button') as Node);
        document.getElementById('close-button')!.style.display = 'flex';
        document.getElementById('close-button')?.classList.add('active', 'call-to-action');
    };

    export const addRoundPoints = async (roundPoints: number) => {
        const roundPointsEl = document.getElementById('round-points');
        roundPointsEl!.innerText = roundPoints.toString();
        roundPointsEl?.classList.add('in');
    };

    const touchSkip = async () => {
        return new Promise((resolve) => {
            window.addEventListener('pointerdown', resolve);
        });
    };

    export const addTotalPoints = async (totalPoints: number) => {
        setMessageButton('Adding points');
        const roundPointsEl = document.getElementById('round-points');
        const totalPointsEl = document.getElementById('points');
        roundPointsEl!.innerText = totalPoints.toString();
        // @ts-ignore
        const topPoints = Math.max.apply(
            Math,
            tierTable.map((tier) => tier.tokensNeeded),
        );
        const daysContainerEl = document.getElementById('days-container');
        const scoreContainerEl = document.getElementById('score-container');
        scoreContainerEl?.classList.add('highlit');
        const skip = touchSkip();
        const pointsCount = new Promise<void>(async (resolve) => {
            await await Promise.race([timeout(1000), skip]);
            for (let i = totalPoints; i >= 0; i--) {
                roundPointsEl!.innerText = i.toString();
                document.getElementById('point-progress')!.style.height =
                    ((points - totalPoints + (totalPoints - i)) * 100) / topPoints + '%';
                const currentPoints = points - totalPoints + (totalPoints - i);
                totalPointsEl!.innerText = currentPoints.toString();
                for (const prizePoints in prizes) {
                    // @ts-ignore
                    if (currentPoints >= prizePoints) {
                        // @ts-ignore
                        prizes[prizePoints].classList.add('point-prize-awarded');
                    }
                }
                await Promise.race([timeout(2000 / totalPoints), skip]);
            }
            roundPointsEl?.classList.remove('in');
            resolve();
        });

        await Promise.race([pointsCount, skip]);

        totalPointsEl!.innerText = points.toString();
        await timeout(1500);
        scoreContainerEl!.classList.remove('highlit');
        await timeout(1500);
        daysContainerEl?.classList.add('highlit');
        showEarnedPrizes();
        parent.postMessage({ type: 'gameRoundEnded' }, '*');
        setMessageButton('See you tomorrow!');
        // @ts-ignore
        renderGameProgress(updatedDates);
        await timeout(1000);
        showCloseButton();
    };

    async function timeout(time: number) {
        return new Promise((resolve) => {
            setTimeout(resolve, time);
        });
    }

    export const openTutorial = () => {
        const tutorial = document.getElementById('tutorial');
        const tutorialContent = document.getElementById('tutorial-content');
        const origin = document.getElementById('days-container');
        tutorial?.classList.remove('outro');
        tutorial?.classList.add('intro');
        tutorialContent?.classList.remove('outro');
        tutorialContent?.classList.add('intro');

        if (!tutorialContent) {
            console.error('Tutorial content not found');

            return;
        }

        AnimateFromTo(
            tutorialContent,
            50,
            50,
            tutorial!.clientWidth / 2,
            tutorial!.clientHeight / 2,
            500,
        );

        setPersistentItem('tutorial-shown', true);

        document.getElementById('tutorial')!.style.pointerEvents = 'initial';
        document.getElementById('tutorial')?.addEventListener('pointerdown', (evt) => {
            // @ts-ignore
            if (evt?.target?.id === 'tutorial') {
                closeTutorial();
            }
        });
    };

    export const closeTutorial = () => {
        const tutorial = document.getElementById('tutorial');
        const tutorialContent = document.getElementById('tutorial-content');
        const origin = document.getElementById('days-container');
        tutorial?.classList.remove('intro');
        tutorial?.classList.add('outro');
        tutorialContent?.classList.remove('intro');
        tutorialContent?.classList.add('outro');

        setPersistentItem('tutorial-closed', true);

        if (!tutorialContent) {
            console.error('Tutorial content not found');

            return;
        }

        AnimateFromTo(
            tutorialContent,
            tutorial!.clientWidth / 2,
            tutorial!.clientHeight / 2,
            50,
            50,
            500,
            true,
        );

        document.getElementById('tutorial')!.style.pointerEvents = 'none';
    };

    export const openPointsTutorial = () => {
        const tutorial = document.getElementById('points-tutorial');
        const tutorialContent = document.getElementById('points-tutorial-content');
        const origin = document.getElementById('score-container');
        tutorial?.classList.remove('outro');
        tutorial?.classList.add('intro');
        tutorialContent?.classList.remove('outro');
        tutorialContent?.classList.add('intro');
        document.getElementById('points-tutorial')!.style.pointerEvents = 'initial';

        if (!tutorialContent) {
            console.error('Tutorial content not found');

            return;
        }

        AnimateFromTo(
            tutorialContent,
            50,
            origin!.offsetTop + origin!.clientHeight,
            tutorial!.clientWidth / 2,
            tutorial!.clientHeight / 2,
            500,
        );
        document.getElementById('points-tutorial')?.addEventListener('pointerdown', (evt) => {
            // @ts-ignore
            if (evt.target.id === 'points-tutorial') {
                closePointsTutorial();
            }
        });
    };

    export const closePointsTutorial = () => {
        const tutorial = document.getElementById('points-tutorial');
        const tutorialContent = document.getElementById('points-tutorial-content');
        const origin = document.getElementById('score-container');
        tutorial?.classList.remove('intro');
        tutorial?.classList.add('outro');
        tutorialContent?.classList.remove('intro');
        tutorialContent?.classList.add('outro');

        if (!tutorialContent) {
            console.error('Tutorial content not found');

            return;
        }

        AnimateFromTo(
            tutorialContent,
            tutorial!.clientWidth / 2,
            tutorial!.clientHeight / 2,
            50,
            origin!.offsetTop,
            500,
            true,
        );
        setPersistentItem('tutorial-closed', true);
        document.getElementById('points-tutorial')!.style.pointerEvents = 'none';
    };

    export const close = () => {
        parent.postMessage({ type: 'quit' }, '*');
    };
}

export default DFGUI;
